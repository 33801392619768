import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import SEO from '../components/seo'

const Termini = ({data}) => {
    if(!data) return null
    const page = data.prismicTerminiECondizioniApplicazioneThelgo20

    return (
        <Layout>
            <SEO title="Termini e Condizioni"/>
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="prose prose-persian-green max-w-none">
                        <h1>{page.data.titolo.text}</h1>
                        {parse(page.data.contenuto.html)}
                    </div>
                </div>
            </section>
        </Layout>
        )
    }
                                        
export default Termini

export const query = graphql`
  {
    prismicTerminiECondizioniApplicazioneThelgo20(uid: {eq: "termini-e-condizioni-dell-applicazione-thelgo"}) {
      id
      uid
      data {
        contenuto {
          html
        }
        titolo {
          text
        }
      }
    }
  }
`